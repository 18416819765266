import axios from 'axios';
import { useEffect, useState } from 'react';
import { CUSTOMER_NOTIFIER_V2_SCOPE, getToken } from '../auth';

const CUSTOMER_NOTIFIER_V2_GET_CONFIG_URL = 'https://customer-notifier.bruteforce-prod.flixtech.io/api/config';

const useGetCN2Config = () => {
    const [responseData, setResponseData] = useState({});
    useEffect(() => {
        const requestConfig = async () => {
            const authToken = await getToken(CUSTOMER_NOTIFIER_V2_SCOPE);
            const response = await axios.get(CUSTOMER_NOTIFIER_V2_GET_CONFIG_URL, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            setResponseData(response.data);
        }

        requestConfig();
    }, [])

    return {
        genericPlaceholders: responseData?.placeholders?.generic,
    }
}

export default useGetCN2Config;