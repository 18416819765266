import React, { useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Heading, Grid, GridCol, Button, NavHorizontal, NavItem } from '@flixbus/honeycomb-react';
import { Icon, IconArrowLeft } from '@flixbus/honeycomb-icons-react';
import TemplateEditor from '../../TemplateEditor';
import { TranslateContext } from '../../../system/Translate';
import { CLOUD_FILTERS_STORAGE_KEY } from '../context/CloudFiltersContext';

export default function EditSection() {
    const { id, shopGroup } = useParams();
    const { translate } = useContext(TranslateContext);
    const history = useHistory();

    const handleBackButtonClick = () => {
        const urlParams = JSON.parse(sessionStorage.getItem(CLOUD_FILTERS_STORAGE_KEY));
        const searchParams = new URLSearchParams();

        Object.entries(urlParams).forEach(([key, values]) => {
            if (Array.isArray(values)) {
                values.forEach((value) => searchParams.append(key, value));
            }
        });

        history.push({
            pathname: '/',
            search: searchParams.toString(),
        });
    };

    return (
        <Grid>
            <GridCol size={12} xl={3}>
                <Grid>
                    <GridCol size={12} xl={9} pushXl={3} lg={12}>
                        <Button appearance="link" onClick={handleBackButtonClick}>
                            <Icon InlineIcon={IconArrowLeft} />
                            {translate('back-to-list')}
                        </Button>
                    </GridCol>
                </Grid>
            </GridCol>
            <GridCol size={12} xl={7}>
                <NavHorizontal aria-label="Shop Group">
                    <NavItem active={shopGroup === 'FLIX'} href={`/edit/${id}/FLIX`}>
                        FLIX
                    </NavItem>
                    <NavItem active={shopGroup === 'GH'} href={`/edit/${id}/GH`}>
                        GH
                    </NavItem>
                    <NavItem active={shopGroup === 'KK'} href={`/edit/${id}/KK`}>
                        KK
                    </NavItem>
                    <NavItem active={shopGroup === 'FLIX_TRAIN'} href={`/edit/${id}/FLIX_TRAIN`}>
                        FlixTrain
                    </NavItem>
                </NavHorizontal>
                <Heading size={2} sectionHeader>
                    {translate('edit-notification')}
                </Heading>
                <TemplateEditor id={id} shopGroup={shopGroup} />
            </GridCol>
        </Grid>
    );
}
