import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button} from '@flixbus/honeycomb-react';
import { Icon, IconQuestion } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../system/Translate';
import SendWhatsAppPlaceholderInput from './components/SendWhatsAppPlaceholderInput/SendWhatsAppPlaceholderInput';
import useGenericPlaceholdersListPopup from '../../hooks/useGenericPlaceholdersListPopup';
import useGetCN2Config from '../../hooks/useGetCN2Config';

export const WHATSAPP_LOCALIZATION_TYPE = 'WHATSAPP_BRAZE';

const SendWhatsAppPlaceholders = ({ template, onPlaceholdersChange }) => {
    const { translate } = useContext(TranslateContext);

    const { openPopup: openGenericPlaceholdersPopup, GenericPlaceholdersListPopup } = useGenericPlaceholdersListPopup();
    const { genericPlaceholders } = useGetCN2Config();

    const {
        bodyPlaceholders,
        headerPlaceholder,
        buttonPlaceholder,
    } = template.localizations.find(({ type }) => type === WHATSAPP_LOCALIZATION_TYPE) || {};

    const initialPlaceholders = {};

    if (headerPlaceholder) {
        initialPlaceholders.headerPlaceholder = headerPlaceholder;
    }
    if (buttonPlaceholder) {
        initialPlaceholders.buttonPlaceholder = buttonPlaceholder;
    }
    if (bodyPlaceholders?.length) {
        initialPlaceholders.bodyPlaceholders = bodyPlaceholders;
    }

    const [, setPlaceholders] = useState(initialPlaceholders);

    useEffect(() => {
        setPlaceholders(initialPlaceholders);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template])

    useEffect(() => {
        onPlaceholdersChange(initialPlaceholders);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template]);

    const handlePlaceholderChange = (type, value, index) => {
        setPlaceholders((prevState) => {
            let updatedPlaceholders = { ...prevState };

            if (type === 'bodyPlaceholders') {
                const updatedBodyPlaceholders = [...prevState.bodyPlaceholders];
                updatedBodyPlaceholders[index] = value;
                updatedPlaceholders.bodyPlaceholders = updatedBodyPlaceholders;
            } else {
                updatedPlaceholders[type] = value;
            }

            onPlaceholdersChange(updatedPlaceholders);

            return updatedPlaceholders;
        });
    };

    if (!bodyPlaceholders?.length && !headerPlaceholder?.length && !buttonPlaceholder?.length) {
        return null;
    }

    return (
        <>
            {headerPlaceholder && (
                <>
                    <h3>{translate('headerPlaceholder')}</h3>
                    <SendWhatsAppPlaceholderInput
                        genericPlaceholders={genericPlaceholders}
                        initialValue={headerPlaceholder}
                        onChange={handlePlaceholderChange}
                        placeholderType="headerPlaceholder"
                    />
                </>
            )}
            {bodyPlaceholders?.length > 0 && (
                <>
                    <h3>{translate('bodyPlaceholders')}</h3>
                    {bodyPlaceholders.map((item, index) => (
                        <SendWhatsAppPlaceholderInput
                            key={item}
                            initialValue={item}
                            genericPlaceholders={genericPlaceholders}
                            onChange={handlePlaceholderChange}
                            placeholderType="bodyPlaceholders"
                            index={index}
                        />
                    ))}
                </>
            )}
            {buttonPlaceholder && (
                <>
                    <h3>{translate('buttonPlaceholder')}</h3>
                    <SendWhatsAppPlaceholderInput
                        initialValue={buttonPlaceholder}
                        genericPlaceholders={genericPlaceholders}
                        onChange={handlePlaceholderChange}
                        placeholderType="buttonPlaceholder"
                    />
                </>
            )}
            <Button
                extraClasses="cn-whatsapp-placeholders__info"
                appearance="link"
                display="block"
                onClick={() => openGenericPlaceholdersPopup(genericPlaceholders)}
            >
                <Icon InlineIcon={IconQuestion} size={4} aria-hidden="true" />
                {translate('placeholders')}
            </Button>
            <GenericPlaceholdersListPopup />
        </>
    );
};

SendWhatsAppPlaceholders.propTypes = {
    template: PropTypes.object,
    onPlaceholdersChange: PropTypes.func,
};

SendWhatsAppPlaceholders.defaultProps = {
    template: {},
    onPlaceholdersChange: () => {},
};

export default SendWhatsAppPlaceholders;