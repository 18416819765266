import React, { useState, useContext } from 'react';
import {
    Button,
    Heading,
    Popup,
    PopupSection,
} from '@flixbus/honeycomb-react';
import { TranslateContext } from '../system/Translate';

const useGenericPlaceholdersListPopup = () => {
    const { translate } = useContext(TranslateContext);
    const [isActive, setIsActive] = useState(false);
    const [placeholders, setPlaceholders] = useState([]);

    const openPopup = (genericPlaceholders) => {
        setPlaceholders(genericPlaceholders);
        setIsActive(true);
    };

    const closePopup = () => {
        setIsActive(false);
    };

    const GenericPlaceholdersListPopup = () => (
        <Popup active={isActive} onOverlayClick={closePopup}>
            <PopupSection type="title">
                <Heading size={2} sectionHeader>
                    Generic Placeholders
                </Heading>
            </PopupSection>
            <PopupSection>
                {placeholders.map((el) => {
                    return <p key={el}>{el}</p>
                })}
            </PopupSection>
            <PopupSection type="actions">
                <Button appearance="primary" onClick={() => closePopup()}>{translate('ok')}</Button>
            </PopupSection>
        </Popup>
    );

    return {
        openPopup,
        closePopup,
        GenericPlaceholdersListPopup,
    };
};

export default useGenericPlaceholdersListPopup;