import React, { useState } from 'react';
import { Input } from '@flixbus/honeycomb-react';
import PropTypes from 'prop-types';

import './SendWhatsAppPlaceholderInput.scss';

const SendWhatsAppPlaceholderInput = ({ initialValue, placeholderType, index, onChange }) => {
    const [value, setValue] = useState(null);

    const handleChange = ({ target }) => {
        setValue(target.value);
        onChange(placeholderType, target.value, index);
    };

    return (
        <Input
            extraClasses="cn__send-whatsapp_input"
            aria-label="placeholder-input"
            id={initialValue}
            value={value ?? initialValue}
            onChange={handleChange}
        />
    )
}

SendWhatsAppPlaceholderInput.propTypes = {
    initialValue: PropTypes.string,
    placeholderType: PropTypes.string,
    index: PropTypes.number,
    onChange: PropTypes.func,
};
SendWhatsAppPlaceholderInput.defaultProps = {
    initialValue: '',
    placeholderType: '',
    index: 0,
    onChange: () => {},
};

export default SendWhatsAppPlaceholderInput;